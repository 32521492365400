@use 'sass:map';
@use '@angular/material' as mat;
@use "@material/button" as button;
@use "@material/shape" as shape;
@import '@angular/material/theming';
@import "@fontsource/open-sans/500.css";
@import "@fontsource/pt-mono/400.css";
// TODO(v15): As of v15 mat.core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-mdc-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.typography-hierarchy(mat.define-typography-config());`
//@include mat.all-component-typographies();

@include mat.typography-hierarchy(mat.define-typography-config($font-family: 'Open Sans', ));
@include mat.core();
$radius: 25px;
$sans-typography: mat.define-typography-config($font-family: 'Open Sans',
  );
@include angular-material-typography($sans-typography);
@include mat.button-typography($sans-typography);

// is we do more with accessability
// @include mat.strong-focus-indicators((
//   border-style: dotted,
//   border-width: 4px,
//   border-radius: 2px,
// ));

$tenasys-red: (
  50 : #f8e4e4,
  100 : #eebcbb,
  200 : #e38f8e,
  300 : #d76260,
  400 : #cf413e,
  500 : #c61f1c,
  600 : #c01b19,
  700 : #b91714,
  800 : #b11211,
  900 : #a40a09,
  A100 : #ffd1d1,
  A200 : #ff9e9e,
  A400 : #ff6b6b,
  A700 : #ff5151,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$tenasys-grey: (
  50 : #e5e5e6,
  100 : #bfbfbf,
  200 : #959595,
  300 : #6a6a6b,
  400 : #4a4a4b,
  500 : #2a2a2b,
  600 : #252526,
  700 : #1f1f20,
  800 : #19191a,
  900 : #0f0f10,
  A100 : #6767f0,
  A200 : #3838ec,
  A400 : #0000f1,
  A700 : #0000d7,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$tenasys-coral: (
  50 : #ffece7,
  100 : #ffd0c3,
  200 : #ffb19b,
  300 : #ff9173,
  400 : #ff7a55,
  500 : #ff6237,
  600 : #ff5a31,
  700 : #ff502a,
  800 : #ff4623,
  900 : #ff3416,
  A100 : #ffffff,
  A200 : #fffcfc,
  A400 : #ffcec9,
  A700 : #ffb8af,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$tenasys-app-primary: mat.define-palette($tenasys-grey, 500, 200, 600); // this is gray
$tenasys-app-accent: mat.define-palette($tenasys-red, 500, 200, 600); // this is red
$tenasys-app-warn: mat.define-palette($tenasys-coral, 500, 200, 600); // this is salmon
$tenasys-app-theme: mat.define-light-theme((color: (primary: $tenasys-app-primary,
        accent: $tenasys-app-accent,
        warn: $tenasys-app-warn ),
      density: 0,
      typography: $sans-typography ));
// Get the color config from the theme.
$color-config: mat.get-color-config($tenasys-app-theme);
$gray-palette: map.get($color-config, 'primary');
$red-palette: map.get($color-config, 'accent');
$salmon-palette: map.get($color-config, 'warn');
@include mat.all-component-themes($tenasys-app-theme);

$theme-border-color: mat.get-color-from-palette($gray-palette, 500);
$theme-splash-backround-color: mat.get-color-from-palette($gray-palette, 50);
$theme-tenasys-red: mat.get-color-from-palette($red-palette, 500);
$theme-background-color: mat.get-color-from-palette($tenasys-coral, A100);
$theme-warn-color: mat.get-color-from-palette($salmon-palette, 500);
$theme-good-color: #50C878;
$theme-toolbar-background-color: mat.get-color-from-palette($gray-palette, 100);
$theme-background-color-info: mat.get-color-from-palette($gray-palette, 50);
$theme-background-panel: $theme-toolbar-background-color;
$theme-card-header-background: mat.get-color-from-palette($gray-palette, 50);
$theme-card-border-color: rgba(0, 0, 0, 0.12);

:root {
  // border
  --theme-border-color: #{$theme-border-color};
  --theme-splash-backround-color: #{$theme-background-color-info};
  --theme-tenasys-red: #{$theme-tenasys-red};
  --theme-background-color: #{$theme-background-color};
  --theme-background-color-info: #{$theme-background-color};
  --theme-background-color-panel: #{$theme-background-panel};
  --theme-warn-color: #{$theme-warn-color};
  --theme-good-color: #{$theme-good-color};
  --theme-toolbar-background-color: #{$theme-toolbar-background-color};
  --theme-card-border-color: #{$theme-card-border-color};
}

* {
  box-sizing: border-box
}

//overrides
html,
body {
  font-family: 'Open Sans';
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-size: 1rem;
}

form {
  width: 100%;
}

hr {
  background-color: $theme-card-border-color;
}

ul {
  list-style-type: square;
}

// tooltip
.tooltip-long {
  border-radius: 5px;
  font-size: 16px;
  padding: 5px;
  vertical-align: text-bottom !important;
}

// toolbar
.mat-toolbar-row {
  height: 70px !important;
}

.breadcrumb {
  background-color: $theme-toolbar-background-color;
  border-top: 1px solid $theme-border-color;
  border-bottom: 1px solid $theme-border-color;
}

.warn {
  color: $theme-warn-color;
  font-weight: bold;
}

.toolbar {
  background-color: $theme-tenasys-red;
  border-top: 1px solid $theme-border-color;
  border-bottom: 1px solid $theme-border-color;
}

.footer {
  background-color: $theme-toolbar-background-color;
}

// landing page drop down
.mat-mdc-option:hover {
  background-color: $theme-tenasys-red !important;
  color: mat.get-color-from-palette($salmon-palette, A200) !important;
}

// forms, fields
.mat-mdc-card,
.mat-mdc-card-outlined {
  padding-bottom: 1em !important;
  border: 1px solid $theme-border-color !important;
  border-radius: 3px !important;
}

.mat-mdc-card-header {
  background-color: $theme-card-header-background;
  border-bottom: 1px solid $theme-border-color !important;
  text-align: left;
  padding: 10px 15px !important;
}

.mat-mdc-card-title {
  margin: .5em;
  font-size: 1.1rem !important;
}

.mat-mdc-card-title span {
  font-size: 1.1rem;
}

.mat-mdc-card-footer {
  margin: .5em;
}

.mat-mdc-card-footer button {
  margin: .5em 2em;
}

.mat-mdc-card-subtitle {
  margin: .5em 16px;
  text-align: left;
}

.mat-mdc-card-content {
  padding: 5px !important;
}

.mat-mdc-dialog-content {
  min-height: 130px;
}

.mat-mdc-input-element {
  caret-color: mat.get-color-from-palette($gray-palette, 900);
  cursor: text;
}

.mat-mdc-header-cell {
  text-decoration: underline;
  border-bottom: 1px solid $theme-border-color;
  font-size: 0.875rem;
  font-weight: 900;
}

.mat-mdc-cell {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
}

.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  letter-spacing: 0.025rem;
  line-height: 1.34;

  font: {
    size: 0.75rem;
    weight: 600;
  }

  mat-label {
    background: mat.get-color-from-palette($salmon-palette, A200);

    &::after {
      background: mat.get-color-from-palette($red-palette, 50);
      content: '';
      display: inline-block;
      width: 0.25rem;
    }
  }
}

.mat-mdc-expanded {
  background: #e4e4e4;
}

.mat-mdc-form-field {
  margin: .5em;
  width: 100% !important;
}

// table common
.mat-mdc-paginator {
  font-size: 1em;
  font-weight: bolder;
}

/* used on the popup modal after adding entitlements */
.cdk-overlay-container {
  z-index: 999999 !important;
}

.cardnopadding-dialog .mat-mdc-dialog-container {
  padding: 0;
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-flat-button,
.mat-mdc-unelevated-button {
  border-radius: $radius !important;
  min-height: 36px !important;
  height: auto !important;
}

.mat-mdc-button:hover,
.mat-mdc-raised-button:hover,
.mat-mdc-outlined-button:hover,
.mat-mdc-flat-button:hover,
.mat-mdc-unelevated-button:hover {
  background-color: $theme-tenasys-red !important;
  color: mat.get-color-from-palette($salmon-palette, A200) !important;
}

/* allows us to show mat-buttons like regular hyperlinks */
.hlink {
  text-decoration: underline !important;
  font-size: 1rem !important;
  font-weight: 600;
  // color: $theme-tenasys-red; <-- use mat-button with color=accent
}

.a {
  color: var(--theme-tenasys-red);
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.padding {
  padding: 1em;
}

.nopadding {
  padding: 0px !important;
}

.mono {
  font-family: "PT Mono";
  font-weight: 700px;
}

.txtcenter {
  text-align: center;
}

.vcenter {
  display: grid;
  align-items: center;
}

dt::after {
  content: ":";
}

dl {
  display: grid;
  grid-template-columns: max-content auto;
  text-align: left;
  padding: 2px 1em;
}

dt {
  grid-column-start: 1;
}

dd {
  grid-column-start: 2;
}

simple-snack-bar {
  white-space: pre-wrap;
}

.custom-dialog-container .mat-dialog-container {
  overflow-y: hidden;
}

input[type='radio'] {
  accent-color: $theme-tenasys-red;
}

// needed for select columns to allow correct overflow
.selectcolumns-dialog div {
  overflow: hidden !important;
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid #e0e0e0  !important;
}

td {
  height: 36px !important;
}

.mat-icon {
  vertical-align: middle;
}